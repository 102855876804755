
// @ts-nocheck
import locale__home_forge_product_module_34ml_com_locale_ar_common_json from "../locale/ar/common.json";
import locale__home_forge_product_module_34ml_com_locale_ar_user_json from "../locale/ar/user.json";
import locale__home_forge_product_module_34ml_com_locale_ar_informative_json from "../locale/ar/informative.json";
import locale__home_forge_product_module_34ml_com_locale_ar_product_json from "../locale/ar/product.json";
import locale__home_forge_product_module_34ml_com_locale_ar_footer_json from "../locale/ar/footer.json";
import locale__home_forge_product_module_34ml_com_locale_ar_checkout_json from "../locale/ar/checkout.json";
import locale__home_forge_product_module_34ml_com_locale_en_common_json from "../locale/en/common.json";
import locale__home_forge_product_module_34ml_com_locale_en_user_json from "../locale/en/user.json";
import locale__home_forge_product_module_34ml_com_locale_en_informative_json from "../locale/en/informative.json";
import locale__home_forge_product_module_34ml_com_locale_en_product_json from "../locale/en/product.json";
import locale__home_forge_product_module_34ml_com_locale_en_footer_json from "../locale/en/footer.json";
import locale__home_forge_product_module_34ml_com_locale_en_checkout_json from "../locale/en/checkout.json";


export const localeCodes =  [
  "ar",
  "en"
]

export const localeLoaders = {
  "ar": [{ key: "../locale/ar/common.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_ar_common_json), cache: true },
{ key: "../locale/ar/user.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_ar_user_json), cache: true },
{ key: "../locale/ar/informative.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_ar_informative_json), cache: true },
{ key: "../locale/ar/product.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_ar_product_json), cache: true },
{ key: "../locale/ar/footer.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_ar_footer_json), cache: true },
{ key: "../locale/ar/checkout.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_ar_checkout_json), cache: true }],
  "en": [{ key: "../locale/en/common.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_en_common_json), cache: true },
{ key: "../locale/en/user.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_en_user_json), cache: true },
{ key: "../locale/en/informative.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_en_informative_json), cache: true },
{ key: "../locale/en/product.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_en_product_json), cache: true },
{ key: "../locale/en/footer.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_en_footer_json), cache: true },
{ key: "../locale/en/checkout.json", load: () => Promise.resolve(locale__home_forge_product_module_34ml_com_locale_en_checkout_json), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "ar",
      "name": "عربي",
      "language": "ar",
      "files": [
        "/home/forge/product-module.34ml.com/locale/ar/common.json",
        "/home/forge/product-module.34ml.com/locale/ar/user.json",
        "/home/forge/product-module.34ml.com/locale/ar/informative.json",
        "/home/forge/product-module.34ml.com/locale/ar/product.json",
        "/home/forge/product-module.34ml.com/locale/ar/footer.json",
        "/home/forge/product-module.34ml.com/locale/ar/checkout.json"
      ],
      "dir": "rtl"
    },
    {
      "code": "en",
      "language": "en",
      "name": "English",
      "files": [
        "/home/forge/product-module.34ml.com/locale/en/common.json",
        "/home/forge/product-module.34ml.com/locale/en/user.json",
        "/home/forge/product-module.34ml.com/locale/en/informative.json",
        "/home/forge/product-module.34ml.com/locale/en/product.json",
        "/home/forge/product-module.34ml.com/locale/en/footer.json",
        "/home/forge/product-module.34ml.com/locale/en/checkout.json"
      ],
      "dir": "ltr"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": false,
  "langDir": "locale",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "ar",
    "name": "عربي",
    "language": "ar",
    "files": [
      {
        "path": "/home/forge/product-module.34ml.com/locale/ar/common.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/ar/user.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/ar/informative.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/ar/product.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/ar/footer.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/ar/checkout.json"
      }
    ],
    "dir": "rtl"
  },
  {
    "code": "en",
    "language": "en",
    "name": "English",
    "files": [
      {
        "path": "/home/forge/product-module.34ml.com/locale/en/common.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/en/user.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/en/informative.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/en/product.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/en/footer.json"
      },
      {
        "path": "/home/forge/product-module.34ml.com/locale/en/checkout.json"
      }
    ],
    "dir": "ltr"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
