import revive_payload_client_4sVQNw7RlN from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/forge/product-module.34ml.com/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/forge/product-module.34ml.com/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/forge/product-module.34ml.com/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_xSLIPjreZL from "/home/forge/product-module.34ml.com/node_modules/nuxt-meta-pixel/dist/runtime/plugin.client.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/home/forge/product-module.34ml.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/home/forge/product-module.34ml.com/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_FRmGFsEaPh from "/home/forge/product-module.34ml.com/.nuxt/plugin.mjs";
import formkitPlugin_pZqjah0RUG from "/home/forge/product-module.34ml.com/.nuxt/formkitPlugin.mjs";
import appConfig_0DhqX9sWMH from "/home/forge/product-module.34ml.com/plugins/appConfig.ts";
import fabric_client_ribBKoWDpT from "/home/forge/product-module.34ml.com/plugins/fabric.client.ts";
import firebaseAuth_HmQJsTUq9K from "/home/forge/product-module.34ml.com/plugins/firebaseAuth.ts";
import sentry_client_shVUlIjFLk from "/home/forge/product-module.34ml.com/plugins/sentry.client.ts";
import tenantConfig_k5oJKmsrJt from "/home/forge/product-module.34ml.com/plugins/tenantConfig.ts";
import webfontloader_client_iX85i8j6U3 from "/home/forge/product-module.34ml.com/plugins/webfontloader.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_xSLIPjreZL,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  plugin_FRmGFsEaPh,
  formkitPlugin_pZqjah0RUG,
  appConfig_0DhqX9sWMH,
  fabric_client_ribBKoWDpT,
  firebaseAuth_HmQJsTUq9K,
  sentry_client_shVUlIjFLk,
  tenantConfig_k5oJKmsrJt,
  webfontloader_client_iX85i8j6U3
]